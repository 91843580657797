import React from 'react';
import Modal from 'react-modal';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Create from './pages/Create';
import MyLibrary from './pages/MyLibrary';
import Discover from './pages/Discover';
import HomePage from './pages/HomePage';
import './App.css';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { SoundsProvider } from './contexts/SoundsContext';
import Debug from './components/Debug';

// Remove this line since we don't need to require routes in the frontend
// const soundsRouter = require('./routes/sounds'); 

Modal.setAppElement('#root');

// Create a ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, isLoading } = useAuth();
  
  console.log('ProtectedRoute - State:', { isLoggedIn, isLoading });
  
  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  if (!isLoggedIn) {
    console.log('ProtectedRoute - Redirecting to home, not logged in');
    return <Navigate to="/" replace />;
  }
  
  return children;
};

function App() {
  return (
    <SoundsProvider>
      <AuthProvider>
        <Router basename="/">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Navigate to="/" replace />} />
            <Route path="/create" element={
              <ProtectedRoute>
                <Create />
              </ProtectedRoute>
            } />
            <Route path="/my-library" element={
              <ProtectedRoute>
                <MyLibrary />
              </ProtectedRoute>
            } />
            <Route path="/discover" element={
              <ProtectedRoute>
                <Discover />
              </ProtectedRoute>
            } />
            <Route path="/sounds/*" element={
              <ProtectedRoute>
                <MyLibrary />
              </ProtectedRoute>
            } />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          {process.env.NODE_ENV === 'development' && <Debug />}
        </Router>
      </AuthProvider>
    </SoundsProvider>
  );
}

export default App;
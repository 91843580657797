import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableSound from '../components/DraggableSound';
import Arranger from '../components/Arranger';
import LoginRegisterModal from '../components/LoginRegisterModal';
import LoginLogoutButton from '../components/LoginLogoutButton';
import { SoundsContext } from '../contexts/SoundsContext';
import { useAuth } from '../contexts/AuthContext';
import { audioManager } from '../utils/audio/audioManager';
import { useArrangementSave } from '../hooks/useArrangementSave';

function Create() {
  console.log('CREATE COMPONENT RENDERED');
  
  const [searchTerm, setSearchTerm] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [user, setUser] = useState({});
  const {userId} = useAuth();
  const { sounds } = useContext(SoundsContext);
  const [isLooping, setIsLooping] = useState(false);
  const [droppedSounds, setDroppedSounds] = useState(Array(5).fill(null));
  const [currentlyPlayingName, setCurrentlyPlayingName] = useState("");
  const [currentlyPlayingSound, setCurrentlyPlayingSound] = useState(null);
  const { saveMessage, saveToLibrary } = useArrangementSave(user.id, isPrivate);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortType, setSortType] = useState('name');

  const handleSaveToLibrary = async () => {
    if (!isLoggedIn) {
      setShowLoginModal(true);
      return;
    }
    setIsLoading(true);
    try {
      await saveToLibrary(sounds, droppedSounds);
    } catch (error) {
      console.error('Error saving arrangement:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const [audioSettings, setAudioSettings] = useState(
    Array(5).fill({ volume: 1, pan: 0 })
  );
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if(token) {
      console.log('Validating JWT...');

      if(userId){
        setUser({ id: userId }); // Set the user state
        setIsLoggedIn(true);
        console.log('Found User ID: ', userId);
      } else {
        setIsLoggedIn(false);
        console.log('User ID could not be found');
      }
    }
  }, [userId, setUser, setIsLoggedIn]);

  useEffect(() => {
    audioManager.setLoopAll(isLooping);
  }, [isLooping]);

  // Global state to keep track of the currently playing audio
  const handlePlaySound = (sound, isPlaying) => {
    console.log(`Sound ${sound.name} is now ${isPlaying ? 'playing' : 'stopped'}`);
    if (isPlaying) {
      setCurrentlyPlayingSound(sound);
    } else {
      setCurrentlyPlayingSound(null);
    }
  };

  const handleDrop = useCallback(async (item, slotIndex) => {
    setIsLoading(true);
    try {
      const newDroppedSounds = [...droppedSounds];
      newDroppedSounds[slotIndex] = item.name;
      setDroppedSounds(newDroppedSounds);
  
      // Preload the sound
      await audioManager.loadSound(item.src);
      setCurrentlyPlayingName(item.name);
    } catch (error) {
      console.error('Error loading sound:', error);
      // Optionally show user feedback
      setCurrentlyPlayingName("Error loading sound");
    } finally {
      setIsLoading(false);
    }
  }, [droppedSounds, setDroppedSounds, setCurrentlyPlayingName]);

  // Update handleVolumeChange
  const handleVolumeChange = useCallback((index, volume) => {
    setAudioSettings(prev => {
      const newSettings = [...prev];
      newSettings[index] = { ...newSettings[index], volume };
      return newSettings;
    });
    
    const sound = sounds.find(s => s.name === droppedSounds[index]);
    if (sound) {
      audioManager.setVolume(sound.src, volume);
    }
  }, [sounds, droppedSounds]);

  // Update handlePanChange
  const handlePanChange = useCallback((index, pan) => {
    setAudioSettings(prev => {
      const newSettings = [...prev];
      newSettings[index] = { ...newSettings[index], pan };
      return newSettings;
    });
    
    const sound = sounds.find(s => s.name === droppedSounds[index]);
    if (sound) {
      audioManager.setPan(sound.src, pan);
    }
  }, [sounds, droppedSounds]);

  const playAllSounds = useCallback(() => {
    try {
      droppedSounds.forEach((soundName, index) => {
        if (soundName) {
          const sound = sounds.find(s => s.name === soundName);
          if (sound) {
            audioManager.playSound(sound.src, {
              loop: isLooping,
              ...audioSettings[index]
            });
          }
        }
      });
      setIsPlaying(true);
      setCurrentlyPlayingName("Current Arrangement");
    } catch (error) {
      console.error('Error playing sounds:', error);
      setIsPlaying(false);
    }
  }, [droppedSounds, sounds, isLooping, audioSettings, setIsPlaying, setCurrentlyPlayingName]);

  // Update clearDroppedSounds
  const clearDroppedSounds = () => {
    audioManager.stopAll();
    setDroppedSounds(Array(5).fill(null));
    setAudioSettings(Array(5).fill({ volume: 1, pan: 0 }));
    setIsPlaying(false);
    setCurrentlyPlayingName("");
  };

  const stopAllSounds = useCallback(() => {
    audioManager.stopAll();
    setIsPlaying(false);
    setCurrentlyPlayingName("");
    setCurrentlyPlayingSound(null);
  }, []);

  // Add sorting function
  const getSortedSounds = useCallback((sounds) => {
    switch(sortType) {
      case 'name':
        return [...sounds].sort((a, b) => a.name.localeCompare(b.name));
      case 'popular':
        return [...sounds].sort((a, b) => (b.totalSaves || 0) - (a.totalSaves || 0));
      case 'date':
        return [...sounds].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      default:
        return sounds;
    }
  }, [sortType]);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      audioManager.stopAll();
      setAudioSettings(Array(5).fill({ volume: 1, pan: 0 }));
      setIsPlaying(false);
      setCurrentlyPlayingName("");
      setCurrentlyPlayingSound(null);
    };
  }, []);

  // Combine filtering and sorting
  const filteredAndSortedSounds = useMemo(() => {
    const result = getSortedSounds(
      sounds.filter(sound => 
        sound.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    console.log('Filtered and sorted sounds:', result);
    return result;
  }, [sounds, searchTerm, getSortedSounds, sortType]);

  console.log('Create component rendering. Current state:', {
    droppedSounds,
    currentlyPlayingName
  });

  useEffect(() => {
    console.log('Sounds from context:', sounds);
  }, [sounds]);

  useEffect(() => {
    console.log('Sounds in Create page:', sounds);
  }, [sounds]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="create-page">  
        <header className="create-header">
          <div className="header-content">
            <input 
              type="text" 
              placeholder="Search for sounds or creators..." 
              className="search-input"
              value={searchTerm} 
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Link to="/discover" className='icon-link'>
              <div className='discover-icon'>
                <h2 className="discover-title">DISCOVER</h2>
              </div>
            </Link>
            <Link to="/create" className='icon-link'>
              <div className='create-icon'>
                <h2 className="create-title" style={{fontSize: 60}}>CREATE</h2>
              </div>
            </Link>
            <Link to="/my-library" className='icon-link'>
              <div className='my-library-icon'>
                <h2 className="my-library-title">MY LIBRARY</h2>
              </div>
            </Link>
          </div>
          <div className='right-header-content'>
            <LoginLogoutButton />
            <Link to="/" className="icon-link">
              <div className="home-icon">
                <span className="icon-text">Home</span><br />
              </div>
            </Link>
          </div>
        </header>

        <div className="sound-sample-container">
          <p className="drag-and-drop-summary">
            Drag sounds to the Arranger on the left to create your own ASMR Arrangement!  <br /><span style={{fontSize:20, color:'white'}}>You can play each sound independently to preview sounds and experiment.<br />Once you drag a sound over, you'll 
            be able to change the pan and volume of each sound to your own satisfaction!</span>
          </p>
          <div className="sort-by-buttons">
            Sort by: 
            <button 
              className="sort-by-name-button"
              onClick={() => setSortType('name')}
            >
              NAME (A-Z)
            </button> 
            <button 
              className="sort-by-popularity-button"
              onClick={() => setSortType('popular')}
            >
              MOST POPULAR
            </button> 
            <button 
              className="sort-by-age-button"
              onClick={() => setSortType('date')}
            >
              UPLOAD DATE
            </button>
          </div>
          <p>Total sounds: {filteredAndSortedSounds.length}</p>
          {filteredAndSortedSounds.map((sound) => {
            console.log('Rendering sound:', sound);
            return (
              <DraggableSound 
                key={sound.id} 
                sound={sound}
                onPlay={handlePlaySound}
                isDropped={droppedSounds.includes(sound.name)} 
              />
            );
          })}
        </div>
        <h2 className="arranger-title">ARRANGER</h2>
        <div className="loop-toggle">
          <label>
            <input
              type="checkbox"
              checked={isLooping}
              onChange={(e) => setIsLooping(e.target.checked)}
            />
            <b> LOOP</b>
          </label>
          
        </div>

        <div className="arranger-create-page">
          {droppedSounds.map((droppedSound, index) => (
            <Arranger 
              key={index} 
              onDrop={handleDrop} 
              index={index} 
              droppedSound={droppedSound}
              onVolumeChange={handleVolumeChange}
              onPanChange={handlePanChange}
            />
          ))}
        </div>
        <label className="make-private-checkbox">
          <input
            type="checkbox"
            checked={isPrivate}
            onChange={(e) => setIsPrivate(e.target.checked)}
          />
          <b> Make Private</b>
        </label>    
        <button 
          onClick={handleSaveToLibrary} 
          className="save-to-library"
          disabled={isLoading}
        >
          {isLoading ? 'Saving...' : 'Save to Library'}
        </button>   
        <LoginRegisterModal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)} setIsLoggedIn={setIsLoggedIn} />
        <button 
          onClick={isPlaying ? stopAllSounds : playAllSounds} 
          className="play-all-button"
        >
          {isPlaying ? 'Stop' : 'Play'}
        </button>
        <button onClick={clearDroppedSounds} className="clear-button">Clear</button>
        {saveMessage && <div className="saved-to-library-result"> {saveMessage}</div>}
        <p className="save-to-library-summary">If you'd like to share your creation with the Mood Meadow community, click Save to Library with "Make Private" unchecked!</p>
        {currentlyPlayingSound && (
          <div>Currently playing: {currentlyPlayingSound.name}</div>
        )}
        {isLoading && <div className="loading-indicator">Loading sound...</div>}
      </div>
    </DndProvider>
  );
}

export default Create;
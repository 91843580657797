// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { apiService } from '../utils/api.service';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      console.log('Starting token verification...');
      setIsLoading(true);
      const token = localStorage.getItem('token');
      
      console.log('Token exists:', !!token);
      
      if (!token) {
        console.log('No token found, setting logged out state');
        setIsLoggedIn(false);
        setUserId(null);
        setIsLoading(false);
        return;
      }

      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        console.log('Token payload:', payload);
        const isExpired = Date.now() >= payload.exp * 1000;
        
        console.log('Token expired:', isExpired);
        
        if (isExpired) {
          console.log('Token expired, logging out...');
          logout();
          setIsLoading(false);
          return;
        }

        console.log('Setting logged in state...');
        setIsLoggedIn(true);
        setUserId(payload.user.id);
      } catch (error) {
        console.error('Token verification failed:', error);
        logout();
      } finally {
        setIsLoading(false);
        console.log('Finished token verification');
      }
    };

    verifyToken();
  }, []);

  const login = (token) => {
    console.log('Login called with token:', token.substring(0, 20) + '...');
    localStorage.setItem('token', token);
    
    const storedToken = localStorage.getItem('token');
    console.log('Token stored successfully:', !!storedToken);
    
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      console.log('Token payload:', payload);
      setIsLoggedIn(true);
      setUserId(payload.user.id);
      localStorage.setItem('userId', payload.user.id);
    } catch (error) {
      console.error('Error parsing token:', error);
    }
  };

  const logout = () => {
    console.log('Logout called');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setIsLoggedIn(false);
    setUserId(null);
    console.log('Logout complete');
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or your loading component
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, userId, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
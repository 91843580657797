import React, { useRef, useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';

const DraggableSound = ({ sound, onPlay, isPlaying }) => {
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [error, setError] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    // Create audio element when component mounts
    const audio = new Audio();
    audioRef.current = audio;

    // Handle loading of audio
    audio.addEventListener('loadeddata', () => {
      setAudioLoaded(true);
      setError(null);
    });

    audio.addEventListener('error', (e) => {
      console.error('Error loading audio:', e);
      setError('Failed to load audio');
    });

    // Set the source
    try {
      // Ensure the URL is properly encoded
      const url = new URL(sound.src);
      audio.src = url.toString();
    } catch (e) {
      console.error('Invalid URL:', sound.src);
      setError('Invalid audio URL');
    }

    return () => {
      audio.pause();
      audio.src = '';
    };
  }, [sound.src]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'sound',
    item: { id: sound.id, name: sound.name, src: sound.src },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    if (!audioRef.current) return;
    
    const audio = audioRef.current;
    audio.addEventListener('ended', handleAudioEnd);
    return () => {
      audio.removeEventListener('ended', handleAudioEnd);
      audio.pause();
    };
  }, []);

  useEffect(() => {
    if (!audioRef.current || !audioLoaded) return;

    if (isPlaying) {
      const playPromise = audioRef.current.play();
      if (playPromise) {
        playPromise.catch(error => {
          console.error('Error playing audio:', error);
        });
      }
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [isPlaying, audioLoaded]);

  const handleAudioEnd = () => {
    onPlay(sound, false);
  };

  const handlePlayStop = () => {
    if (!audioLoaded) {
      console.warn('Audio not yet loaded');
      return;
    }
    onPlay(sound, !isPlaying);
  };

  return (
    <div ref={drag} className="sound-sample" style={{ opacity: isDragging ? 0.5 : 1 }}>
      {sound.name}
      {error && <div className="error-message">{error}</div>}
      <p />
      <button 
        onClick={handlePlayStop} 
        className="play-stop-sound-sample"
        disabled={!audioLoaded || error}
      >
        {isPlaying ? 'Stop' : 'Play'}
      </button>
    </div>
  );
};

export default DraggableSound;
import React, { createContext, useState, useEffect } from 'react';
import { apiService } from '../utils/api.service';
import { useAuth } from './AuthContext';

export const SoundsContext = createContext();

export const SoundsProvider = ({ children }) => {
  const [sounds, setSounds] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    const fetchAndLoadSounds = async () => {
      try {
        if (!auth || !auth.isLoggedIn || auth.isLoading) {
          return;
        }

        const soundsResponse = await apiService.get('/sounds');
        console.log('Sounds from DB:', soundsResponse.data);

        const soundsWithUrls = await Promise.all(
          soundsResponse.data.map(async (sound) => {
            try {
              console.log(`Fetching sound: ${sound.name}, key: ${sound.key}`);
              
              const response = await apiService.get(`/get-file/${encodeURIComponent(sound.key)}`, {
                responseType: 'blob'
              });
              
              return {
                ...sound,
                src: URL.createObjectURL(new Blob([response.data], { type: 'audio/wav' }))
              };
            } catch (error) {
              console.error(`Error loading URL for ${sound.name}:`, error.response || error);
              return sound;
            }
          })
        );
        console.log('Loaded sounds with URLs:', soundsWithUrls);
        setSounds(soundsWithUrls);
      } catch (error) {
        console.error('Error in fetchAndLoadSounds:', error);
      }
    };

    fetchAndLoadSounds();
  }, [auth]);

  return (
    <SoundsContext.Provider value={{ sounds, setSounds }}>
      {children}
    </SoundsContext.Provider>
  );
};
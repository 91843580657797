// At the very top
console.log('Loading API Service...');

// Temporarily hardcode the API URL for testing
const API_URL = process.env.REACT_APP_API_URL || 'https://api.dev.moodmeadow.com/api';
console.log('Using hardcoded API_URL:', API_URL);

class ApiService {
  constructor() {
    this.baseUrl = API_URL;
    console.log('ApiService initialized with baseUrl:', this.baseUrl);
    
    // Add method URL logging
    this.logEndpointUrl('register', `${this.baseUrl}/register`);
    this.logEndpointUrl('login', `${this.baseUrl}/login`);
    this.logEndpointUrl('sounds', `${this.baseUrl}/sounds`);
    this.logEndpointUrl('userArrangements', `${this.baseUrl}/userArrangements`);
    this.logEndpointUrl('userLibraries', `${this.baseUrl}/userLibraries`);
  }

  logEndpointUrl(endpoint, url) {
    console.log(`${endpoint} endpoint URL:`, url);
  }

  getHeaders() {
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json'
    };
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      console.log('Adding auth header to request');
    } else {
      console.log('No token found in localStorage');
    }
    
    return headers;
  }

  async handleResponse(response, endpoint) {
    console.log(`Response from ${endpoint}:`, {
      status: response.status,
      statusText: response.statusText
    });

    if (!response.ok) {
      const error = await response.json().catch(() => ({}));
      console.error(`Error from ${endpoint}:`, error);
      throw new Error(error.message || `Failed to fetch ${endpoint}`);
    }

    return response.json();
  }

  async fetchSounds() {
    try {
      const response = await fetch(`${this.baseUrl}/sounds`, {
        headers: this.getHeaders()
      });
      return this.handleResponse(response, 'sounds');
    } catch (error) {
      console.error('fetchSounds error:', error);
      throw error;
    }
  }

  async fetchPublicArrangements() {
    const response = await fetch(`${this.baseUrl}/userArrangements/public-arrangements`, {
      headers: this.getHeaders()
    });
    if (!response.ok) throw new Error('Failed to fetch public arrangements');
    return response.json();
  }

  async fetchUserArrangements() {
    const response = await fetch(`${this.baseUrl}/userLibraries`, {
      headers: this.getHeaders()
    });
    if (!response.ok) throw new Error('Failed to fetch user arrangements');
    return response.json();
  }

  async saveArrangement(arrangement) {
    const response = await fetch(`${this.baseUrl}/userLibraries/save`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({ arrangement })
    });
    if (!response.ok) throw new Error('Failed to save arrangement');
    return response.json();
  }

  async deleteArrangement(userId, arrangementId) {
    const response = await fetch(`${this.baseUrl}/userLibraries/${userId}/arrangements/${arrangementId}`, {
      method: 'DELETE',
      headers: this.getHeaders()
    });
    if (!response.ok) throw new Error('Failed to delete arrangement');
    return response.json();
  }
}

// Add debug log before exporting
const apiServiceInstance = new ApiService();
console.log('ApiService instance created with baseUrl:', apiServiceInstance.baseUrl);
export const apiService = apiServiceInstance;